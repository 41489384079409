<template>
  <BreadCrumbs title="Download the App" />
  <section class="Feautes section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features">
            <div class="signle-icon">
              <i class="icofont icofont-tasks-alt"></i>
            </div>
            <h3>{{ $t("download.features[0].title") }}</h3>
            <p>{{ $t("download.features[0].description") }}</p>
          </div>
          <!-- End Single features -->
        </div>
        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features last">
            <div class="signle-icon">
              <i class="icofont icofont-worker"></i>
            </div>
            <h3>{{ $t("download.features[1].title") }}</h3>
            <p>{{ $t("download.features[1].description") }}</p>
          </div>
          <!-- End Single features -->
        </div>
      </div>
      <br />
      <br />
      <br />
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title">
            <h2>{{ $t("download.howTo.title") }}</h2>
            <img src="img/section-img.png" alt="#" />
            <h6>{{ $t("download.howTo.description") }}</h6>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="single-features">
            <a href="/">
              <img
                :src="$t('download.links[0].image')"
                alt=""
                class="img-fluid"
              />
            </a>
            <p>{{ $t("download.links[0].store") }}</p>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="single-features last">
            <a href="/">
              <img src="/img/qrcode-apple.png" alt="" class="img-fluid" />
            </a>
            <p>{{ $t("download.links[1].store") }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs.vue";

export default {
  name: "DownloadPage",
  components: {
    BreadCrumbs,
  },
};
</script>
