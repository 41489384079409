<template>
  <section class="Feautes section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title">
            <br />
            <h2>{{ $t("faq.header") }}</h2>
            <img src="img/section-img.png" alt="#" />
            <p>
              {{ $t("faq.description") }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features">
            <div class="signle-icon">
              <i class="icofont icofont-tasks-alt"></i>
            </div>
            <h3>{{ $t("faq.feature1.title") }}</h3>
            <p>
              {{ $t("faq.feature1.description") }}
            </p>
          </div>
          <!-- End Single features -->
        </div>
        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features last">
            <div class="signle-icon">
              <i class="icofont icofont-ui-user-group"></i>
            </div>
            <h3>{{ $t("faq.feature2.title") }}</h3>
            <p>
              {{ $t("faq.feature2.description") }}
            </p>
          </div>
          <!-- End Single features -->
        </div>

        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features">
            <div class="signle-icon">
              <i class="icofont icofont-checked"></i>
            </div>
            <h3>{{ $t("faq.feature3.title") }}</h3>
            <p>
              {{ $t("faq.feature3.description") }}
            </p>
          </div>
          <!-- End Single features -->
        </div>

        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features last">
            <div class="signle-icon">
              <i class="icofont icofont-checked"></i>
            </div>
            <h3>{{ $t("faq.feature4.title") }}</h3>
            <p>
              {{ $t("faq.feature4.description") }}
            </p>
          </div>
          <!-- End Single features -->
        </div>
        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features">
            <div class="signle-icon">
              <i class="icofont icofont-ui-user-group"></i>
            </div>
            <h3>{{ $t("faq.feature5.title") }}</h3>
            <p>
              {{ $t("faq.feature5.description") }}
            </p>
          </div>
          <!-- End Single features -->
        </div>
        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features last">
            <div class="signle-icon">
              <i class="icofont icofont-tasks-alt"></i>
            </div>
            <h3>{{ $t("faq.feature6.title") }}</h3>
            <p>
              {{ $t("faq.feature6.description") }}
            </p>
          </div>
          <!-- End Single features -->
        </div>

        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features">
            <div class="signle-icon">
              <i class="icofont icofont-tasks-alt"></i>
            </div>
            <h3>{{ $t("faq.feature7.title") }}</h3>
            <p>
              {{ $t("faq.feature7.description") }}
            </p>
          </div>
          <!-- End Single features -->
        </div>
        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features last">
            <div class="signle-icon">
              <i class="icofont icofont-ui-user-group"></i>
            </div>
            <h3>{{ $t("faq.feature8.title") }}</h3>
            <p>
              {{ $t("faq.feature8.description") }}
            </p>
          </div>
          <!-- End Single features -->
        </div>

        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features">
            <div class="signle-icon">
              <i class="icofont icofont-checked"></i>
            </div>
            <h3>{{ $t("faq.feature9.title") }}</h3>
            <p>
              {{ $t("faq.feature9.description") }}
            </p>
          </div>
          <!-- End Single features -->
        </div>

        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features last">
            <div class="signle-icon">
              <i class="icofont icofont-checked"></i>
            </div>
            <h3>{{ $t("faq.feature10.title") }}</h3>
            <p>
              {{ $t("faq.feature10.description") }}
            </p>
          </div>
          <!-- End Single features -->
        </div>
        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features">
            <div class="signle-icon">
              <i class="icofont icofont-ui-user-group"></i>
            </div>
            <h3>{{ $t("faq.feature11.title") }}</h3>
            <p>
              {{ $t("faq.feature11.description") }}
            </p>
          </div>
          <!-- End Single features -->
        </div>
        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features last">
            <div class="signle-icon">
              <i class="icofont icofont-tasks-alt"></i>
            </div>
            <h3>{{ $t("faq.feature12.title") }}</h3>
            <p>
              {{ $t("faq.feature12.description") }}
            </p>
          </div>
          <!-- End Single features -->
        </div>
        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features">
            <div class="signle-icon">
              <i class="icofont icofont-tasks-alt"></i>
            </div>
            <h3>{{ $t("faq.feature13.title") }}</h3>
            <p>
              {{ $t("faq.feature13.description") }}
            </p>
          </div>
          <!-- End Single features -->
        </div>
        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features last">
            <div class="signle-icon">
              <i class="icofont icofont-tasks-alt"></i>
            </div>
            <h3>{{ $t("faq.feature14.title") }}</h3>
            <p>
              {{ $t("faq.feature14.description") }}
            </p>
          </div>
          <!-- End Single features -->
        </div>
        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features">
            <div class="signle-icon">
              <i class="icofont icofont-ui-user-group"></i>
            </div>
            <h3>{{ $t("faq.feature15.title") }}</h3>
            <p>
              {{ $t("faq.feature15.description") }}
            </p>
          </div>
          <!-- End Single features -->
        </div>
        <div class="col-lg-6 col-12">
          <!-- Start Single features -->
          <div class="single-features last">
            <div class="signle-icon">
              <i class="icofont icofont-checked"></i>
            </div>
            <h3>{{ $t("faq.feature16.title") }}</h3>
            <p>
              {{ $t("faq.feature16.description") }}
            </p>
          </div>
          <!-- End Single features -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MyFAQ",
};
</script>
