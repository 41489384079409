<template>
  <BreadCrumbs title="header.contactUs" />
  <ContactUs />
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import ContactUs from "@/components/Contact.vue";

export default {
  name: "ContactPage",
  components: { ContactUs, BreadCrumbs },
};
</script>
