<template>
  <BreadCrumbs title="services.sectionTitle" />
  <FindService />
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import FindService from "@/components/Services.vue";

export default {
  name: "FindServicePage",
  components: {
    FindService,
    BreadCrumbs,
  },
};
</script>
