<template>
  <section class="schedule">
    <div class="container">
      <div class="schedule-inner">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12">
            <!-- single-schedule -->
            <div class="single-schedule first">
              <div class="inner">
                <div class="icon">
                  <i class="fa fa-ambulance"></i>
                </div>
                <div class="single-content">
                  <h4>{{ $t("schedule.item1.title") }}</h4>
                  <p>{{ $t("schedule.item1.description") }}</p>
                  <a href="#"
                    >{{ $t("schedule.item1.learnMore") }}
                    <i class="fa fa-long-arrow-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <!-- single-schedule -->
            <div class="single-schedule middle">
              <div class="inner">
                <div class="icon">
                  <i class="icofont-prescription"></i>
                </div>
                <div class="single-content">
                  <h4>{{ $t("schedule.item2.title") }}</h4>
                  <p>{{ $t("schedule.item2.description") }}</p>
                  <a href="#"
                    >{{ $t("schedule.item2.learnMore") }}
                    <i class="fa fa-long-arrow-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-12">
            <!-- single-schedule -->
            <div class="single-schedule last">
              <div class="inner">
                <div class="icon">
                  <i class="icofont-ui-clock"></i>
                </div>
                <div class="single-content">
                  <h4>{{ $t("schedule.item3.title") }}</h4>
                  <p>{{ $t("schedule.item3.description") }}</p>
                  <a href="#"
                    >{{ $t("schedule.item3.learnMore") }}
                    <i class="fa fa-long-arrow-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeSchedule",
};
</script>
