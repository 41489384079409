<template>
  <!-- Breadcrumbs -->
  <div class="breadcrumbs overlay">
    <div class="container">
      <div class="bread-inner">
        <div class="row">
          <div class="col-12">
            <h2>{{ $t(title) }}</h2>
            <ul class="bread-list">
              <li>
                <a href="/">{{ $t("header.home") }}</a>
              </li>
              <li><i class="icofont-simple-right"></i></li>
              <li class="active">{{ $t(title) }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Breadcrumbs -->
</template>

<script>
export default {
  name: "BreadCrumbs",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
