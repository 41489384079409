<template>
  <section class="slider">
    <div class="hero-slider">
      <!-- Slide 1 -->
      <div
        class="single-slider"
        style="background-image: url('img/slider.png')"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div class="text">
                <h1 v-html="$t('slider.slide1.title')"></h1>
                <h4 v-html="$t('slider.slide1.subtitle')"></h4>
                <p>{{ $t("slider.slide1.description") }}</p>
                <div class="button">
                  <a href="/taches-postes" class="btn">{{
                    $t("slider.slide1.earnMoneyButton")
                  }}</a>
                  <a href="/download-app" class="btn primary">{{
                    $t("slider.slide1.learnMoreButton")
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Slide 2 -->
      <div
        class="single-slider"
        style="background-image: url('img/slider3.png')"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div class="text">
                <h1 v-html="$t('slider.slide2.title')"></h1>
                <p>{{ $t("slider.slide2.description") }}</p>
                <div class="button">
                  <a href="/services" class="btn">{{
                    $t("slider.slide2.findServicesButton")
                  }}</a>
                  <a href="/a-propos-de-nous" class="btn primary">{{
                    $t("slider.slide2.aboutUsButton")
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeSlider",
};
</script>
