<template>
  <!-- Start Contact Us -->
  <section class="contact-us section">
    <div class="container">
      <div class="inner">
        <div class="row">
          <div class="col-lg-12">
            <div class="contact-us-form">
              <h2>{{ $t("contactUs.contactWithTitle") }}</h2>
              <p>{{ $t("contactUs.contactSubtitle") }}</p>
              <!-- Form -->
              <form class="form" method="post">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="formData.name"
                        type="text"
                        name="name"
                        :placeholder="$t('contactUs.nameLabel')"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        v-model="formData.email"
                        type="email"
                        name="email"
                        :placeholder="$t('contactUs.emailLabel')"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <input
                        v-model="formData.subject"
                        type="text"
                        name="subject"
                        :placeholder="$t('contactUs.subjectLabel')"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <textarea
                        v-model="formData.message"
                        name="message"
                        :placeholder="$t('contactUs.messageLabel')"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-12 my-3">
                    <div v-if="formStatus === 'loading'" class="loading">
                      {{ $t("contactUs.formLoading") }}
                    </div>
                    <div v-if="formStatus === 'error'" class="error-message">
                      {{ $t("contactUs.formError") }}
                    </div>
                    <div v-if="formStatus === 'success'" class="sent-message">
                      {{ $t("contactUs.formSuccess") }}
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group login-btn">
                      <button
                        class="btn"
                        type="submit"
                        @click.prevent="submitForm"
                      >
                        {{ $t("contactUs.sendButton") }}
                      </button>
                    </div>
                    <div class="checkbox">
                      <label class="checkbox-inline" for="2">
                        <input name="news" id="2" type="checkbox" />
                        {{ $t("contactUs.subscribeLabel") }}
                      </label>
                    </div>
                  </div>
                </div>
              </form>
              <!--/ End Form -->
            </div>
          </div>
        </div>
      </div>
      <div class="contact-info">
        <div class="row">
          <!-- single-info -->
          <div class="col-lg-4 col-12">
            <div class="single-info">
              <i class="icofont icofont-ui-call"></i>
              <div class="content">
                <h3>{{ $t("contactUs.phoneNumber") }}</h3>
                <p>{{ $t("contactUs.emailAddress") }}</p>
              </div>
            </div>
          </div>
          <!--/End single-info -->
          <!-- single-info -->
          <div class="col-lg-4 col-12">
            <div class="single-info">
              <i class="icofont-google-map"></i>
              <div class="content">
                <h3>{{ $t("contactUs.addressLine1") }}</h3>
                <p>{{ $t("contactUs.addressLine2") }}</p>
              </div>
            </div>
          </div>
          <!--/End single-info -->
          <!-- single-info -->
          <div class="col-lg-4 col-12">
            <div class="single-info">
              <i class="icofont icofont-wall-clock"></i>
              <div class="content">
                <h3>{{ $t("contactUs.openingHours") }}</h3>
                <p>{{ $t("contactUs.closedOnSunday") }}</p>
              </div>
            </div>
          </div>
          <!--/End single-info -->
        </div>
      </div>
    </div>
  </section>
  <!--/ End Contact Us -->
</template>

<script>
import axios from "axios";

export default {
  name: "ContactUs",
  data() {
    return {
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
        from: "WaMerci",
      },
      formStatus: null, // To keep track of the form submission status
    };
  },
  methods: {
    async submitForm() {
      try {
        //Send form data to server
        this.formStatus = "loading";

        const response = await axios.post(
          "https://api.wamerci.com/api/v1/store_contact_us",
          this.formData
        );

        //Check if form submission was successful
        if (response.data.status == "success") {
          this.formStatus = "success";
          // Clear form data after successful submission
          this.formData = {
            name: "",
            email: "",
            subject: "",
            message: "",
          };
        } else {
          this.formStatus = "error";
        }
      } catch (error) {
        // console.error("Error submitting form:", error);
        this.formStatus = "error";
      }
    },
  },
};
</script>
