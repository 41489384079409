<template>
  <section class="services section">
    <div class="container">
      <div class="row">
        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-star"></i>
            <h4>
              <a href="/download-app">{{
                $t("services.modelInfluencer.title")
              }}</a>
            </h4>
            <p>{{ $t("services.modelInfluencer.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-checked"></i>
            <h4>
              <a href="/download-app">{{
                $t("services.writerTranslator.title")
              }}</a>
            </h4>
            <p>{{ $t("services.writerTranslator.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-airplane-alt"></i>
            <h4>
              <a href="/download-app">{{
                $t("services.flyerDelivery.title")
              }}</a>
            </h4>
            <p>{{ $t("services.flyerDelivery.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-home"></i>
            <h4>
              <a href="/download-app">{{
                $t("services.homeCaretaker.title")
              }}</a>
            </h4>
            <p>{{ $t("services.homeCaretaker.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-bucket"></i>
            <h4>
              <a href="/download-app">{{ $t("services.cleaner.title") }}</a>
            </h4>
            <p>{{ $t("services.cleaner.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-camera-alt"></i>
            <h4>
              <a href="/download-app">{{
                $t("services.photographerVideographer.title")
              }}</a>
            </h4>
            <p>{{ $t("services.photographerVideographer.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-hammer"></i>
            <h4>
              <a href="/download-app">{{
                $t("services.temporaryStaff.title")
              }}</a>
            </h4>
            <p>{{ $t("services.temporaryStaff.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-architecture"></i>
            <h4>
              <a href="/download-app">{{ $t("services.renovator.title") }}</a>
            </h4>
            <p>{{ $t("services.renovator.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-ambulance-cross"></i>
            <h4>
              <a href="/download-app">{{
                $t("services.deliveryHelper.title")
              }}</a>
            </h4>
            <p>{{ $t("services.deliveryHelper.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-baby"></i>
            <h4>
              <a href="/download-app">{{
                $t("services.nannyBabysitter.title")
              }}</a>
            </h4>
            <p>{{ $t("services.nannyBabysitter.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-brush"></i>
            <h4>
              <a href="/download-app">{{
                $t("services.designSupport.title")
              }}</a>
            </h4>
            <p>{{ $t("services.designSupport.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-worker"></i>
            <h4>
              <a href="/download-app">{{ $t("services.plumber.title") }}</a>
            </h4>
            <p>{{ $t("services.plumber.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-circuit"></i>
            <h4>
              <a href="/download-app">{{
                $t("services.electriciansAircond.title")
              }}</a>
            </h4>
            <p>{{ $t("services.electriciansAircond.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-tools"></i>
            <h4>
              <a href="/download-app">{{
                $t("services.applianceService.title")
              }}</a>
            </h4>
            <p>{{ $t("services.applianceService.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-paint"></i>
            <h4>
              <a href="/download-app">{{ $t("services.painter.title") }}</a>
            </h4>
            <p>{{ $t("services.painter.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-code-alt"></i>
            <h4>
              <a href="/download-app">{{
                $t("services.webSoftwareDeveloper.title")
              }}</a>
            </h4>
            <p>{{ $t("services.webSoftwareDeveloper.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-truck-alt"></i>
            <h4>
              <a href="/download-app">{{ $t("services.transporter.title") }}</a>
            </h4>
            <p>{{ $t("services.transporter.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-car-alt-4"></i>
            <h4>
              <a href="/download-app">{{
                $t("services.carWashService.title")
              }}</a>
            </h4>
            <p>{{ $t("services.carWashService.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-ui-user"></i>
            <h4>
              <a href="/download-app">{{
                $t("services.makeupArtist.title")
              }}</a>
            </h4>
            <p>{{ $t("services.makeupArtist.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-cart-alt"></i>
            <h4>
              <a href="/download-app">{{
                $t("services.personalShopper.title")
              }}</a>
            </h4>
            <p>{{ $t("services.personalShopper.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->

        <!-- Start Single Service -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service">
            <i class="icofont icofont-graduate"></i>
            <h4>
              <a href="/download-app">{{ $t("services.homeTuition.title") }}</a>
            </h4>
            <p>{{ $t("services.homeTuition.description") }}</p>
          </div>
        </div>
        <!-- End Single Service -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MyService",
};
</script>
