export default {
  header: {
    aboutUs: "À propos de Nous",
    contact: "Contactez-nous",
    helpCenter: "Centre d'aide",
    phoneNumber: "+221 33 961 02 99",
    emailAddress: "{'hello@wamerci.com'}",
    languageLink: "🇬🇧", // French language link
    home: "Accueil",
    taskPosted: "Tâche postée",
    services: "Services",
    howItWorks: "Comment ça marche",
    contactUs: "Contactez-nous",
    postTask: "Publier une tâche",
    logoAltText: "Logo",
  },
  slider: {
    slide1: {
      title: "Bienvenue à <span>WaMerci</span>",
      subtitle:
        "Connectez-vous avec des <span>professionnels</span> locaux pour vos besoins de service.",
      description:
        "Découvrez une nouvelle façon de trouver des professionnels locaux fiables et qualifiés. Notre plateforme conviviale vous permet de vous connecter rapidement avec les experts dont vous avez besoin.",
      earnMoneyButton: "Gagner de l'argent avec WaMerci",
      learnMoreButton: "En savoir plus",
    },
    slide2: {
      title:
        "Obtenez le <span>meilleur service </span> à votre budget ou <span>gagnez</span> le meilleur tarif avec vos <span>compétences</span>",
      description:
        "Rejoignez WaMerci dès aujourd'hui et débloquez un monde de commodité et de fiabilité pour tous vos besoins de service. Que ce soit une réparation à domicile, la planification d'événements ou des services de tutorat, nous avons ce qu'il vous faut.",
      findServicesButton: "Trouver des Services",
      aboutUsButton: "À propos de Nous",
    },
  },
  schedule: {
    item1: {
      title: "Connexion Facile",
      description:
        "Connectez-vous instantanément avec un réseau de professionnels de confiance prêts à répondre à vos besoins.",
      learnMore: "EN SAVOIR PLUS",
    },
    item2: {
      title: "Assurance Qualité",
      description:
        "Chaque prestataire de services sur WaMerci est sélectionné pour son excellence, vous garantissant ainsi un service de première qualité à chaque fois.",
      learnMore: "EN SAVOIR PLUS",
    },
    item3: {
      title: "Transactions Sécurisées",
      description:
        "Notre système de paiement robuste garantit que vos transactions sont sécurisées, vous offrant la tranquillité d'esprit tout au long du processus.",
      learnMore: "EN SAVOIR PLUS",
    },
  },
  features: {
    title: "Comment fonctionne WaMerci ?",
    description: "Engagement de service simplifié",
    feature1: {
      title: "1. Poster Vos Besoins de Service",
      description:
        "Les utilisateurs peuvent créer des listes détaillées de leurs besoins de service, en spécifiant le type de service requis, l'emplacement et toutes les échéances ou préférences spécifiques.",
    },
    feature2: {
      title: "2. Recevoir des Offres de Professionnels",
      description:
        "Les professionnels qualifiés du réseau WaMerci peuvent consulter ces annonces et soumettre des offres adaptées aux besoins de l'utilisateur.",
    },
    feature3: {
      title: "3. Comparer et Sélectionner la Meilleure Offre",
      description:
        "Les utilisateurs peuvent comparer les offres de différents professionnels, en tenant compte de facteurs tels que la qualité, le prix et la réputation du prestataire.",
    },
    feature4: {
      title: "4. Traitement Sécurisé des Paiements",
      description:
        "WaMerci facilite les transactions de paiement sécurisées et transparentes entre les utilisateurs et les prestataires de services.",
    },
    feature5: {
      title: "5. Achèvement et Validation du Travail",
      description:
        "Après avoir sélectionné une offre, le professionnel choisi réalise le travail convenu selon les modalités décrites dans l'offre.",
    },
    feature6: {
      title: "6. Transfert de Fonds",
      description:
        "Une fois le travail validé et approuvé par l'utilisateur, WaMerci facilite le transfert transparent des fonds du client au prestataire de services.",
    },
  },
  funFacts: {
    users: "Utilisateurs",
    professionals: "Professionnels",
    servicesPosted: "Services Publiés",
    servicesDone: "Services Effectués",
  },
  blogSection: {
    title: "Découvrez une variété de demandes de service",
    description: `Parcourez une gamme diversifiée de demandes de service publiées par des utilisateurs comme vous. Des réparations domiciliaires et du paysagisme aux projets de design graphique et de tutorat en langue, il y a quelque chose pour chaque compétence et intérêt.`,
  },
  newsletterSection: {
    title: "Inscrivez-vous à notre newsletter",
    description:
      "N'hésitez pas à le personnaliser davantage pour correspondre au ton et aux spécificités de votre newsletter !",
    placeholder: "Votre adresse e-mail",
    subscribeButton: "S'abonner",
  },
  footer: {
    downloadAppTitle: "Télécharger l'application",
    quickLinksTitle: "Liens Rapides",

    aboutUs: "À propos de Nous",
    home: "Accueil",

    termsAndConditions: "Termes et conditions",
    privacyAndPolicies: "Politique de confidentialité",
    communityEthics: "Éthique communautaire",
    services: "Services",
    howItWorks: "Comment ça fonctionne",
    tasksPosted: "Tâches Postées",
    faq: "FAQ",
    contactUs: "Contactez-nous",
    discoverTitle: "Découvrir",
    followUsOn: "Suivez-nous sur :",
    customerServices: "Service client",
    emailAddress: "{'hello@wamerci.com'}",
    copyrightText: "© WaMerci 2018 Tous droits réservés | Conçu par",
  },
  contactUs: {
    contactWithTitle: "Contactez-nous",
    contactSubtitle:
      "Si vous avez des questions, n'hésitez pas à nous contacter.",
    nameLabel: "Nom",
    emailLabel: "Email",
    phoneLabel: "Téléphone",
    subjectLabel: "Sujet",
    messageLabel: "Votre message",
    sendButton: "Envoyer",
    subscribeLabel: "Voulez-vous vous abonner à notre newsletter ?",
    phoneNumber: "+(221) 33 961 02 99",
    emailAddress: "{'contact@wamerci.com'}",
    addressLine1: "Saint-Louis",
    addressLine2: "Route Nationale 2, Sor",
    openingHours: "Lun - Sam: 8h - 17h",
    closedOnSunday: "Fermé le dimanche",
    formLoading: "Chargement...",
    formError:
      "Oops! Quelque chose s'est mal passé. Veuillez réessayer ultérieurement.",
    formSuccess: "Votre message a été envoyé. Merci !",
  },
  services: {
    sectionTitle: "Nos Services",
    sectionSubtitle:
      "Découvrez les différents services que nous proposons pour répondre à vos besoins.",
    modelInfluencer: {
      title: "Modèle & Influenceur",
      description:
        "Découvrez des modèles et influenceurs talentueux pour vos collaborations ou campagnes promotionnelles. Nos professionnels peuvent améliorer la portée et l'engagement de votre marque.",
    },
    writerTranslator: {
      title: "Rédacteur & Traducteur",
      description:
        "Trouvez des rédacteurs et traducteurs qualifiés pour vous aider avec vos projets. Que vous ayez besoin de création de contenu, de traduction de documents ou de services linguistiques, nos professionnels sont là pour vous aider.",
    },
    flyerDelivery: {
      title: "Distribution de Flyers",
      description:
        "Besoin d'une distribution efficace de flyers pour votre entreprise ou événement ? Nos professionnels veillent à ce que vos flyers atteignent le bon public, augmentant ainsi votre visibilité et votre engagement.",
    },
    homeCaretaker: {
      title: "Gardien de Maison",
      description:
        "Confiez l'entretien de votre maison à des professionnels fiables. Du ménage à la garde d'animaux, nos gardiens veillent à ce que votre maison soit bien entretenue et sécurisée.",
    },
    cleaner: {
      title: "Nettoyeur",
      description:
        "Engagez des nettoyeurs professionnels pour garder votre espace impeccable et organisé. Du nettoyage résidentiel aux services de nettoyage commerciaux, nos nettoyeurs garantissent un environnement propre et sain.",
    },
    photographerVideographer: {
      title: "Photographe & Vidéaste",
      description:
        "Capturez les moments de la vie avec des photographes et vidéastes qualifiés. Que vous ayez besoin de portraits professionnels, de couverture d'événements ou de vidéos promotionnelles, nos experts fournissent des visuels époustouflants.",
    },
    temporaryStaff: {
      title: "Personnel Temporaire",
      description:
        "Répondez à vos besoins en personnel avec des professionnels temporaires. Du soutien administratif au personnel pour événements, notre personnel temporaire est compétent et fiable pour répondre à vos besoins à court terme.",
    },
    renovator: {
      title: "Rénovateur",
      description:
        "Transformez votre espace avec des rénovateurs expérimentés. Que ce soit pour une rénovation de maison ou un projet de rénovation commerciale, nos rénovateurs fournissent un artisanat de qualité et des solutions de design.",
    },
    deliveryHelper: {
      title: "Assistant de Livraison",
      description:
        "Besoin d'une aide pour les livraisons ? Engagez un assistant de livraison fiable pour garantir que vos colis arrivent à destination en toute sécurité et à temps. Nos professionnels sont prêts à vous aider avec vos besoins de livraison.",
    },
    nannyBabysitter: {
      title: "Nounou/Garde d'Enfants",
      description:
        "Confiez les soins de votre enfant à des nounous et gardes d'enfants expérimentées. Que vous ayez besoin de garde d'enfants pour une soirée ou d'un soutien régulier, nos aidants garantissent un environnement sûr et nourrissant pour vos petits.",
    },
    designSupport: {
      title: "Support Design",
      description:
        "Obtenez un support design professionnel pour vos projets. Que vous ayez besoin de design graphique, de design UI/UX ou d'aide à la marque, nos experts fournissent des solutions créatives adaptées à vos besoins.",
    },
    plumber: {
      title: "Plombier",
      description:
        "Besoin de services de plomberie ? Engagez des plombiers qualifiés pour résoudre vos problèmes de plomberie efficacement. Des réparations de tuyaux aux installations, nos plombiers assurent des solutions fiables pour vos besoins en plomberie.",
    },
    electriciansAircond: {
      title: "Électriciens & Service de Climatisation",
      description:
        "Assurez-vous que vos systèmes électriques et de climatisation sont en parfait état avec des électriciens professionnels et des prestataires de services de climatisation. Des installations aux réparations, nos experts maintiennent vos systèmes en bon état de fonctionnement.",
    },
    applianceService: {
      title: "Service & Réparation d'Appareils",
      description:
        "Assurez le bon fonctionnement de vos appareils avec un service et une réparation professionnels. Des machines à laver aux réfrigérateurs, nos experts fournissent des solutions fiables pour garder vos appareils en parfait état.",
    },
    painter: {
      title: "Peintre",
      description:
        "Transformez votre espace avec des services de peinture experts. Que vous ayez besoin de peinture intérieure ou extérieure, nos peintres offrent un travail de qualité et une attention aux détails pour réaliser votre vision.",
    },
    webSoftwareDeveloper: {
      title: "Développeur Web & Logiciel",
      description:
        "Développez votre présence en ligne avec des développeurs web et logiciels qualifiés. De la conception de sites web aux solutions logicielles personnalisées, nos développeurs créent des solutions sur mesure pour répondre à vos besoins numériques.",
    },
    transporter: {
      title: "Transporteur",
      description:
        "Besoin de services de transport fiables ? Engagez des transporteurs professionnels pour une livraison sûre et efficace de vos marchandises. Des livraisons locales au transport longue distance, nos transporteurs veillent à ce que vos articles arrivent à destination en toute sécurité.",
    },
    carWashService: {
      title: "Lavage & Entretien de Voitures",
      description:
        "Gardez votre voiture en parfait état avec des services professionnels de lavage et de detailing. Du lavage extérieur au nettoyage intérieur, nos experts assurent que votre véhicule brille de l'intérieur comme de l'extérieur.",
    },
    makeupArtist: {
      title: "Maquilleur",
      description:
        "Sublimez votre beauté naturelle avec l'aide de maquilleurs qualifiés. Que ce soit pour une occasion spéciale ou un look quotidien, nos maquilleurs créent des looks magnifiques adaptés à vos préférences.",
    },
    personalShopper: {
      title: "Acheteur Personnel",
      description:
        "Gagnez du temps et profitez d'expériences de shopping personnalisées avec des acheteurs personnels professionnels. De la mode aux courses, nos acheteurs personnels répondent à vos besoins et préférences.",
    },
    homeTuition: {
      title: "Cours Particuliers à Domicile",
      description:
        "Améliorez votre expérience d'apprentissage avec des services de cours particuliers à domicile. Nos tuteurs fournissent des leçons personnalisées pour vous aider à atteindre vos objectifs académiques depuis le confort de votre maison.",
    },
  },
  whyChoose: {
    sectionTitle: "Dynamiser Votre Parcours de Service avec WaMerci",
    sectionImage: "img/section-img.png",
    sectionSubtitle:
      "Bienvenue sur WaMerci - Où l'Excellence du Service Rencontre l'Innovation!",
    whoWeAreTitle: "Qui sommes-nous",
    whoWeAreText:
      "Chez WaMerci, nous ne révolutionnons pas seulement la façon dont les gens accèdent aux services ; nous redéfinissons l'ensemble du paysage des services. Notre plateforme est bien plus qu'un simple marché ; c'est un écosystème dynamique conçu pour connecter les individus avec les meilleurs professionnels de leur région de manière fluide.",
    missionTitle: "Notre Mission : Simplifier les Solutions de Service",
    missionText:
      "WaMerci est né du désir de simplifier le processus souvent complexe et chronophage de recherche de prestataires de services fiables. Nous croyons que tout le monde mérite un accès à des services de haute qualité sans tracas, et notre mission est de rendre cela possible.",
    whyTitle: "Pourquoi WaMerci ?",
    reason1:
      "Approche Centrée sur la Communauté : Nous sommes plus qu'une simple plateforme ; nous sommes une communauté d'enthousiastes du service dédiée à rendre votre vie plus facile.",
    reason2:
      "Innovation au Coeur : Avec une technologie de pointe et des solutions innovantes, nous élevons constamment la barre de l'excellence du service.",
    reason3:
      "Votre Satisfaction, Notre Priorité : Votre satisfaction n'est pas seulement importante pour nous ; c'est notre moteur. Nous faisons tout pour nous assurer que chaque interaction avec WaMerci dépasse vos attentes.",
    rightImage: "img/about.svg",
  },
  faq: {
    header: "Nous sommes là pour vous aider",
    description:
      "Trouvez des réponses aux questions courantes dans notre section FAQ complète. Que vous soyez à la recherche de services ou un prestataire, nous avons les informations utiles pour répondre à vos préoccupations.",
    feature1: {
      title: "1. Qu'est-ce que WaMerci ?",
      description:
        "WaMerci est un projet visant à créer un portail électronique communautaire qui facilite la connexion entre les individus et les professionnels locaux pour répondre à leurs besoins en matière de services.",
    },
    feature2: {
      title: "2. Comment puis-je publier mes besoins en services sur WaMerci ?",
      description:
        "Vous pouvez publier vos besoins en services sur WaMerci en utilisant la plateforme conviviale mise à votre disposition. Il vous suffit de décrire vos besoins en termes de services requis, en spécifiant les détails pertinents tels que la nature du service, le lieu et les délais.",
    },
    feature3: {
      title:
        "3. Comment les professionnels peuvent-ils soumettre des offres en réponse à mes besoins ?",
      description:
        "Les professionnels qualifiés et intéressés peuvent soumettre des offres en réponse à vos besoins publiés en fournissant des détails sur leurs services, leurs compétences, leurs tarifs et leur disponibilité via la plateforme WaMerci.",
    },
    feature4: {
      title: "4. Comment puis-je sélectionner l'offre optimale ?",
      description:
        "Vous pouvez comparer les différentes offres reçues des professionnels et sélectionner celle qui correspond le mieux à vos besoins en tenant compte de divers facteurs tels que la qualité, le prix et la réputation du prestataire.",
    },
    feature5: {
      title: "5. Comment sont assurés les paiements sécurisés sur WaMerci ?",
      description:
        "WaMerci assure un processus de paiement sécurisé et transparent pour les utilisateurs en facilitant les transactions financières à travers sa plateforme, garantissant ainsi la confiance et la sécurité des transactions.",
    },
    feature6: {
      title:
        "6. Que se passe-t-il une fois que j'ai sélectionné un professionnel pour réaliser le travail ?",
      description:
        "Une fois que vous avez sélectionné un professionnel pour réaliser le travail, ce dernier le réalisera selon les termes et les conditions définis dans l'offre acceptée par vous, en fournissant des services de qualité dans les délais convenus.",
    },
    feature7: {
      title:
        "7. Que faire si j'ai des problèmes ou des conflits avec le travail réalisé ?",
      description:
        "Vous pouvez valider la qualité et la conformité du travail réalisé avec le soutien de l'équipe WaMerci. En cas de problème ou de conflit, notre équipe est là pour vous aider à résoudre la situation.",
    },
    feature8: {
      title:
        "8. Comment sont transférés les fonds entre les parties une fois le travail accompli ?",
      description:
        "WaMerci facilite le transfert des fonds du client au prestataire de services une fois que le travail a été validé et approuvé par les utilisateurs, conformément aux accords convenus entre les parties, assurant ainsi la transparence et la fiabilité des transactions financières.",
    },
    feature9: {
      title:
        "9. Est-ce que WaMerci garantit la qualité des services proposés par les professionnels ?",
      description:
        "WaMerci s'efforce de maintenir des normes de qualité élevées en sélectionnant des professionnels qualifiés et en supervisant le processus de validation du travail. Cependant, la qualité finale des services dépend également de la communication entre les utilisateurs et les prestataires de services.",
    },
    feature10: {
      title:
        "10. Comment puis-je être sûr de la fiabilité des professionnels sur WaMerci ?",
      description:
        "WaMerci met en place des mécanismes de vérification pour s'assurer de la fiabilité des professionnels inscrits sur sa plateforme. Cela peut inclure des vérifications des antécédents, des évaluations des clients précédents et des critères de sélection stricts.",
    },
    feature11: {
      title: "11. Y a-t-il des frais associés à l'utilisation de WaMerci ?",
      description:
        "WaMerci peut facturer des frais de service pour faciliter les transactions et maintenir la plateforme opérationnelle. Ces frais peuvent varier en fonction du type de service et des accords conclus entre les parties.",
    },
    feature12: {
      title:
        "12. Comment puis-je contacter le support client de WaMerci en cas de besoin ?",
      description:
        "Pour contacter le support client de WaMerci, vous pouvez généralement trouver un lien ou un formulaire de contact sur le site Web de la plateforme. Vous pouvez également rechercher des informations de contact dans la section d'aide ou de FAQ.",
    },
    feature13: {
      title:
        "13. Comment puis-je gérer les paramètres de mon compte sur WaMerci ?",
      description:
        "Pour gérer les paramètres de votre compte sur WaMerci, connectez-vous à votre compte et accédez à la section des paramètres où vous pouvez mettre à jour vos informations de profil, vos préférences de notification et d'autres détails de votre compte.",
    },
    feature14: {
      title: "14. WaMerci est-il disponible dans toutes les régions ?",
      description:
        "WaMerci peut être disponible dans certaines régions spécifiques, en fonction de la disponibilité des professionnels et de la demande des utilisateurs. Veuillez vérifier la disponibilité de la plateforme dans votre région spécifique.",
    },
    feature15: {
      title: "15. Quels types de services puis-je trouver sur WaMerci ?",
      description:
        "WaMerci vise à offrir une variété de services pour répondre aux besoins divers des utilisateurs. Cela peut inclure des services tels que la réparation à domicile, le soutien informatique, le jardinage, le nettoyage, et bien d'autres encore. La gamme de services disponibles peut varier en fonction de la région et de la demande locale.",
    },
    feature16: {
      title: "16. WaMerci protège-t-il mes informations personnelles ?",
      description:
        "WaMerci prend la protection de la vie privée au sérieux et met en place des mesures de sécurité pour protéger vos informations personnelles. Vos données sont généralement utilisées uniquement dans le but de faciliter les transactions et de fournir un service efficace, conformément à la politique de confidentialité de WaMerci.",
    },
  },
  CommunityEthics: {
    title: "Code de Conduite de la Communauté",
    sections: {
      introduction: {
        title: "1. Introduction",
        content:
          "Chez WaMerci, nous nous engageons à maintenir un environnement respectueux, professionnel et inclusif pour tous les utilisateurs et professionnels de notre plateforme. Ce Code de Conduite décrit les attentes en matière de comportement et d'interaction au sein de notre communauté.",
      },
      respectAndCourtesy: {
        title: "2. Respect et Courtoisie",
        content:
          "Nous attendons de tous les membres de la communauté qu'ils se traitent mutuellement avec respect et courtoisie. Le respect des opinions, des croyances et des cultures des autres est fondamental pour un environnement sain.",
        points: [
          "Langage approprié : Utilisez un langage respectueux et évitez les insultes, les attaques personnelles et les propos discriminatoires.",
          "Respect des différences : Soyez ouvert aux différences de culture, d'opinion et de croyance.",
          "Comportement en ligne : Maintenez une conduite professionnelle et respectueuse dans toutes les communications en ligne.",
        ],
      },
      professionalIntegrity: {
        title: "3. Intégrité Professionnelle",
        content:
          "Les professionnels doivent agir avec intégrité et transparence dans toutes leurs interactions avec les utilisateurs. L'honnêteté et l'éthique sont essentielles pour maintenir la confiance sur notre plateforme.",
        points: [
          "Transparence : Fournissez des informations claires et honnêtes sur vos services, compétences et tarifs.",
          "Engagement : Respectez les engagements pris avec les clients et fournissez des services de haute qualité.",
          "Confidentialité : Protégez les informations personnelles des clients et respectez leur vie privée.",
        ],
      },
      conflictResolution: {
        title: "4. Résolution des Conflits",
        content:
          "En cas de conflit ou de désaccord, nous encourageons une résolution constructive et respectueuse. Les parties impliquées doivent faire preuve de bonne foi pour parvenir à une solution satisfaisante.",
        points: [
          "Communication ouverte : Engagez une discussion ouverte et honnête pour résoudre les problèmes.",
          "Médiation : En cas de besoin, demandez l'aide de notre équipe de support pour faciliter la résolution du conflit.",
          "Respect des décisions : Acceptez les décisions prises par la médiation et agissez en conséquence.",
        ],
      },
      prohibitedBehavior: {
        title: "5. Comportement Prohibé",
        content:
          "Certaines actions sont strictement interdites sur notre plateforme. Les comportements suivants ne seront pas tolérés :",
        points: [
          "Harcèlement : Tout comportement de harcèlement ou de menace envers d'autres membres de la communauté.",
          "Discrimination : Comportement discriminatoire fondé sur la race, le sexe, l'orientation sexuelle, la religion ou toute autre caractéristique protégée.",
          "Fraude : Toute forme de tromperie ou de fraude dans les interactions avec les utilisateurs.",
          "Usage abusif : Utilisation abusive ou non autorisée de la plateforme, y compris le spam et les publicités non sollicitées.",
        ],
      },
      memberResponsibilities: {
        title: "6. Responsabilités des Membres",
        content:
          "Chaque membre de la communauté est responsable de ses actions et doit s'assurer qu'il respecte ce Code de Conduite. En cas de violation, des mesures appropriées seront prises pour maintenir l'intégrité de la plateforme.",
        points: [
          "Conformité : Adhérez aux règles et directives établies dans ce Code de Conduite.",
          "Signalement : Signalez tout comportement ou activité suspecte ou inappropriée à notre équipe de support.",
          "Responsabilité : Assumez la responsabilité de vos actions et de leur impact sur la communauté.",
        ],
      },
      contactAndReporting: {
        title: "7. Contact et Signalement",
        content:
          "Pour toute question concernant ce Code de Conduite ou pour signaler des violations, veuillez contacter notre équipe de support à ",
      },
    },
  },
  PrivacyPolicy: {
    title: "Politique de Confidentialité",
    sections: {
      introduction: {
        title: "1. Introduction",
        content:
          "Chez WaMerci, nous nous engageons à protéger votre vie privée. Cette politique de confidentialité explique comment nous collectons, utilisons, et protégeons vos informations personnelles lorsque vous utilisez notre site web et nos services.",
      },
      informationCollection: {
        title: "2. Collecte des Informations",
        content:
          "Nous collectons diverses informations lorsque vous utilisez notre plateforme, y compris :",
        points: [
          "Informations personnelles : Nom, adresse email, numéro de téléphone, et autres informations de contact.",
          "Informations de compte : Détails sur votre compte utilisateur, y compris les préférences et les interactions avec notre service.",
          "Données de paiement : Informations nécessaires pour traiter les transactions financières, comme les détails de carte de crédit.",
          "Informations d'utilisation : Données relatives à votre utilisation du site, y compris votre adresse IP, votre navigateur et vos actions sur la plateforme.",
        ],
      },
      informationUse: {
        title: "3. Utilisation des Informations",
        content: "Nous utilisons vos informations personnelles pour :",
        points: [
          "Fournir et améliorer nos services : Pour répondre à vos besoins et améliorer notre plateforme.",
          "Traitement des paiements : Pour gérer les transactions financières de manière sécurisée.",
          "Communication : Pour vous envoyer des informations importantes, des mises à jour, et des offres promotionnelles (avec votre consentement).",
          "Analyse : Pour analyser l'utilisation de notre site et améliorer nos services.",
        ],
      },
      informationProtection: {
        title: "4. Protection des Informations",
        content:
          "Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles pour protéger vos informations personnelles contre la perte, l'accès non autorisé, la divulgation, la modification, ou la destruction.",
        points: [
          "Cryptage : Utilisation de protocoles de cryptage pour sécuriser les données lors des transmissions.",
          "Contrôles d'accès : Limitation de l'accès aux informations personnelles uniquement aux employés et prestataires autorisés.",
          "Audits de sécurité : Réalisation régulière d'audits pour identifier et corriger les vulnérabilités.",
        ],
      },
      informationSharing: {
        title: "5. Partage des Informations",
        content:
          "Nous ne partageons vos informations personnelles avec des tiers que dans les situations suivantes :",
        points: [
          "Avec votre consentement : Lorsque vous avez donné votre accord pour partager vos informations.",
          "Avec des prestataires de services : Pour des services tiers qui nous aident à fournir ou à améliorer nos services.",
          "Pour des obligations légales : Si nous sommes tenus de divulguer des informations pour se conformer à la loi ou aux règlements.",
          "En cas de transfert d'entreprise : En cas de fusion, acquisition, ou vente de notre entreprise.",
        ],
      },
      yourRights: {
        title: "6. Vos Droits",
        content:
          "En tant qu'utilisateur, vous avez plusieurs droits concernant vos informations personnelles :",
        points: [
          "Droit d'accès : Vous pouvez demander l'accès à vos informations personnelles que nous détenons.",
          "Droit de rectification : Vous pouvez demander la correction des informations inexactes ou incomplètes.",
          "Droit à l'effacement : Vous pouvez demander la suppression de vos informations personnelles sous certaines conditions.",
          "Droit à la limitation du traitement : Vous pouvez demander la limitation de l'utilisation de vos informations personnelles.",
          "Droit d'opposition : Vous pouvez vous opposer à l'utilisation de vos informations personnelles à des fins de marketing.",
        ],
        conclusion:
          "Pour exercer vos droits, veuillez nous contacter via les informations fournies à la section suivante.",
      },
      cookies: {
        title: "7. Cookies et Technologies Similaires",
        content:
          "Nous utilisons des cookies et des technologies similaires pour améliorer votre expérience sur notre site. Vous pouvez configurer votre navigateur pour refuser les cookies, mais cela peut affecter certaines fonctionnalités du site.",
      },
      policyUpdates: {
        title: "8. Modifications de la Politique de Confidentialité",
        content:
          "Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. Toute modification sera publiée sur cette page avec une date de révision mise à jour. Nous vous encourageons à consulter régulièrement cette politique pour rester informé.",
      },
      contact: {
        title: "9. Contact",
        content:
          "Si vous avez des questions concernant cette politique de confidentialité ou si vous souhaitez exercer vos droits, veuillez nous contacter à ",
      },
    },
  },
  TermsConditions: {
    title: "Conditions d'Utilisation",
    sections: {
      introduction: {
        title: "1. Introduction",
        content:
          "Bienvenue sur WaMerci. En utilisant notre site web et nos services, vous acceptez de vous conformer aux conditions d'utilisation suivantes. Veuillez lire attentivement ces conditions avant d'utiliser notre plateforme.",
      },
      servicePosting: {
        title: "2. Publication des Besoins en Services",
        content:
          "Les utilisateurs ont la possibilité de publier leurs besoins en services sur notre plateforme. Lors de la publication, il est essentiel de fournir des informations détaillées, y compris la nature du service, le lieu, les délais, et toute exigence spécifique.",
        points: [
          "Nature du service : Décrire précisément le service requis.",
          "Lieu : Indiquer l'emplacement où le service est nécessaire.",
          "Délais : Mentionner les délais souhaités pour l'exécution du service.",
          "Exigences spécifiques : Ajouter toute exigence ou condition particulière.",
        ],
      },
      offerSubmission: {
        title: "3. Soumission des Offres",
        content:
          "Les professionnels peuvent répondre aux demandes de services en soumettant des offres via la plateforme. Les offres doivent inclure les informations suivantes :",
        points: [
          "Description des services : Détails sur les services offerts.",
          "Compétences : Qualifications et compétences pertinentes du prestataire.",
          "Tarifs : Coût des services proposés, y compris toute taxe applicable.",
          "Disponibilité : Dates et heures auxquelles le professionnel est disponible.",
        ],
      },
      offerSelection: {
        title: "4. Sélection de l'Offre",
        content:
          "Les utilisateurs sont responsables de la sélection de l'offre qui correspond le mieux à leurs besoins. Les critères de sélection peuvent inclure :",
        points: [
          "Qualité : Vérifier les compétences et l'expérience du professionnel.",
          "Prix : Comparer les tarifs proposés.",
          "Réputation : Consulter les évaluations et les commentaires d'autres utilisateurs.",
          "Délais : Assurer que les délais de réalisation sont compatibles avec vos besoins.",
        ],
      },
      securePayment: {
        title: "5. Paiement Sécurisé",
        content:
          "Le paiement pour les services doit être effectué via notre plateforme, assurant la sécurité et la transparence des transactions. Nous utilisons des technologies de cryptage pour protéger les informations financières des utilisateurs.",
        points: [
          "Méthodes de paiement : Carte de crédit, PayPal, et autres méthodes sécurisées.",
          "Confirmation de paiement : Recevez une confirmation par email après chaque transaction.",
          "Politique de remboursement : Consultez notre politique de remboursement pour plus de détails.",
        ],
      },
      workCompletion: {
        title: "6. Réalisation du Travail",
        content:
          "Les professionnels doivent réaliser les services conformément aux termes convenus dans l'offre acceptée. Le respect des délais et de la qualité est obligatoire. Toute modification ou demande supplémentaire doit être convenue entre l'utilisateur et le professionnel.",
        points: [
          "Respect des termes : Les services doivent être exécutés comme spécifié.",
          "Communication : Informer l'utilisateur de tout changement ou retard.",
          "Qualité : Assurer la satisfaction du client en fournissant des services de haute qualité.",
        ],
      },
      workValidation: {
        title: "7. Validation du Travail",
        content:
          "Après l'exécution des services, les utilisateurs doivent valider le travail réalisé. WaMerci assure la supervision et intervient en cas de litige ou de problème. Les utilisateurs ont la possibilité de signaler des problèmes ou de demander des corrections.",
        points: [
          "Processus de validation : Examinez et approuvez le travail via la plateforme.",
          "Support : Contactez notre équipe pour toute assistance ou litige.",
          "Corrections : Les professionnels doivent effectuer les corrections demandées dans un délai raisonnable.",
        ],
      },
      fundTransfer: {
        title: "8. Transfert des Fonds",
        content:
          "Les fonds seront transférés au prestataire de services uniquement après validation du travail par l'utilisateur. WaMerci assure la transparence dans la gestion des paiements et des transferts de fonds.",
        points: [
          "Délais de transfert : Les paiements seront effectués dans les délais prévus après validation.",
          "Transparence : Suivez l'état des paiements via la plateforme.",
          "Confirmation : Recevez une confirmation de transfert une fois les fonds envoyés.",
        ],
      },
      termsModification: {
        title: "9. Modifications des Conditions",
        content:
          "WaMerci se réserve le droit de modifier ces conditions d'utilisation à tout moment. Les modifications seront publiées sur cette page et entreront en vigueur dès leur publication. Il est de votre responsabilité de consulter régulièrement ces conditions.",
      },
      responsibilityLimitation: {
        title: "10. Responsabilités et Limitation de Responsabilité",
        content:
          "WaMerci ne peut être tenu responsable des problèmes rencontrés entre les utilisateurs et les professionnels. Nous agissons en tant qu'intermédiaire et ne garantissons pas la qualité des services fournis. Les utilisateurs doivent exercer leur jugement lors de la sélection des professionnels.",
      },
      contact: {
        title: "11. Contact",
        content:
          "Pour toute question relative à ces conditions d'utilisation ou pour toute autre demande, veuillez nous contacter à ",
      },
    },
  },
  download: {
    features: [
      {
        title: "Obtenez les meilleurs services à votre budget",
        description:
          "Les utilisateurs peuvent créer des annonces détaillées de leurs besoins en services, en spécifiant le type de service requis, le lieu, et toute exigence ou délai spécifique.",
      },
      {
        title: "Gagnez le meilleur tarif avec vos compétences",
        description:
          "Les professionnels qualifiés du réseau WaMerci peuvent consulter ces annonces et soumettre des offres adaptées aux besoins de l'utilisateur.",
      },
    ],
    howTo: {
      title: "Comment faire ?",
      description:
        "Cliquez ou scannez le code QR et téléchargez l'application WaMerci depuis PlayStore ou AppleStore",
    },
    links: [
      {
        store: "PlayStore",
        image: "/img/qrcode-apple.png",
      },
      {
        store: "AppleStore",
        image: "/img/qrcode-apple.png",
      },
    ],
  },
};
