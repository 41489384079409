<template>
  <section class="Feautes section">
    <div class="container">
      <div class="row" v-if="display">
        <div class="col-lg-12">
          <div class="section-title">
            <h2>{{ $t("features.title") }}</h2>
            <p>{{ $t("features.description") }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-12">
          <!-- Start Single features -->
          <div class="single-features">
            <div class="signle-icon">
              <i class="icofont icofont-tasks-alt"></i>
            </div>
            <h3>{{ $t("features.feature1.title") }}</h3>
            <p>{{ $t("features.feature1.description") }}</p>
          </div>
          <!-- End Single features -->
        </div>
        <div class="col-lg-4 col-12">
          <!-- Start Single features -->
          <div class="single-features">
            <div class="signle-icon">
              <i class="icofont icofont-ui-user-group"></i>
            </div>
            <h3>{{ $t("features.feature2.title") }}</h3>
            <p>{{ $t("features.feature2.description") }}</p>
          </div>
          <!-- End Single features -->
        </div>
        <div class="col-lg-4 col-12">
          <!-- Start Single features -->
          <div class="single-features last">
            <div class="signle-icon">
              <i class="icofont icofont-checked"></i>
            </div>
            <h3>{{ $t("features.feature3.title") }}</h3>
            <p>{{ $t("features.feature3.description") }}</p>
          </div>
          <!-- End Single features -->
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-12">
          <!-- Start Single features -->
          <div class="single-features">
            <div class="signle-icon">
              <i class="icofont icofont-pay"></i>
            </div>
            <h3>{{ $t("features.feature4.title") }}</h3>
            <p>{{ $t("features.feature4.description") }}</p>
          </div>
          <!-- End Single features -->
        </div>
        <div class="col-lg-4 col-12">
          <!-- Start Single features -->
          <div class="single-features">
            <div class="signle-icon">
              <i class="icofont icofont-worker"></i>
            </div>
            <h3>{{ $t("features.feature5.title") }}</h3>
            <p>{{ $t("features.feature5.description") }}</p>
          </div>
          <!-- End Single features -->
        </div>
        <div class="col-lg-4 col-12">
          <!-- Start Single features -->
          <div class="single-features last">
            <div class="signle-icon">
              <i class="icofont icofont-send-mail"></i>
            </div>
            <h3>{{ $t("features.feature6.title") }}</h3>
            <p>{{ $t("features.feature6.description") }}</p>
          </div>
          <!-- End Single features -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HowWorks",
  props: {
    display: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
