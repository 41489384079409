<template>
  <section class="newsletter section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12">
          <!-- Start Newsletter Form -->
          <div class="subscribe-text">
            <h6>{{ $t("newsletterSection.title") }}</h6>
            <p>{{ $t("newsletterSection.description") }}</p>
          </div>
          <!-- End Newsletter Form -->
        </div>
        <div class="col-lg-6 col-12">
          <!-- Start Newsletter Form -->
          <div class="subscribe-form">
            <form
              action="mail/mail.php"
              method="get"
              target="_blank"
              class="newsletter-inner"
            >
              <input
                name="EMAIL"
                :placeholder="$t('newsletterSection.placeholder')"
                class="common-input"
                onfocus="this.placeholder = ''"
                onblur="this.placeholder = '{{ $t('newsletterSection.placeholder') }}'"
                required=""
                type="email"
              />
              <button class="btn">
                {{ $t("newsletterSection.subscribeButton") }}
              </button>
            </form>
          </div>
          <!-- End Newsletter Form -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NewsLetter",
};
</script>
