import { createRouter, createWebHistory } from "vue-router";

import HomePage from "@/pages/HomePage.vue";
import TermConditionsPage from "@/pages/TermConditionsPage.vue";
import PrivacyPolicyPage from "@/pages/PrivacyPolicyPage.vue";
import FindServicePage from "@/pages/FindServicePage.vue";
import AboutPage from "@/pages/AboutPage.vue";
import TaskPostedPage from "@/pages/TaskPostedPage.vue";
import HowItWorksPage from "@/pages/HowItWorksPage.vue";
import HelpCenterPage from "@/pages/HelpCenterPage.vue";
import ContactPage from "@/pages/ContactPage.vue";
import CommunityEthicsPage from "@/pages/CommunityEthicsPage.vue";
import DownloadPage from "@/pages/DownloadPage.vue";
import NotFoundPage from "@/pages/NotFoundPage.vue";

const routes = [
  {
    path: "/",
    component: HomePage,
    name: "Home",
  },

  {
    path: "/conditions-utilisations",
    component: TermConditionsPage,
    name: "TermConditions",
  },
  {
    path: "/politique-de-confidentialite",
    component: PrivacyPolicyPage,
    name: "PrivacyPolicy",
  },
  {
    path: "/ethique-communautaire",
    component: CommunityEthicsPage,
    name: "CommunityEthics",
  },
  {
    path: "/a-propos-de-nous",
    component: AboutPage,
    name: "About",
  },
  {
    path: "/comment-ca-fonctionne",
    component: HowItWorksPage,
    name: "HowItWorks",
  },
  {
    path: "/taches-postes",
    component: TaskPostedPage,
    name: "TaskPosted",
  },
  {
    path: "/services",
    component: FindServicePage,
    name: "FindService",
  },
  {
    path: "/centre-aide",
    component: HelpCenterPage,
    name: "HelpCenter",
  },
  {
    path: "/contactez-nous",
    component: ContactPage,
    name: "Contact",
  },
  {
    path: "/download-app",
    component: DownloadPage,
    name: "Download",
  },
  {
    path: "/:catchAll(.*)", // Catch all routes that do not match any path
    name: "NotFound",
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // Always scroll to the top when navigating
    return { top: 0 };
  },
});
// router.beforeEach((to, from, next) => {
//   console.log(`Navigating to: ${to.path}`);
//   console.log(`Matched routes: ${to.matched.length}`);

//   if (to.matched.length === 0) {
//     console.log("No matched routes, redirecting to home.");
//     next("/");
//   } else {
//     next();
//   }
// });
export default router;
