<template>
  <!-- Preloader -->
  <div class="preloader">
    <div class="loader">
      <div class="loader-outter"></div>
      <div class="loader-inner"></div>

      <div class="indicator">
        <svg width="16px" height="12px">
          <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
          <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
        </svg>
      </div>
    </div>
  </div>
  <!-- End Preloader -->

  <!-- Get Pro Button -->
  <!-- <ul class="pro-features">
    <a class="get-pro" href="#">Get IT</a>
    <li class="big-title">Pro Version Available on Themeforest</li>
    <li class="title">Pro Version Features</li>
    <li>2+ premade home pages</li>
    <li>20+ html pages</li>
    <li>Color Plate With 12+ Colors</li>
    <li>Sticky Header / Sticky Filters</li>
    <li>Working Contact Form With Google Map</li>
    <div class="button">
      <a
        href="http://preview.themeforest.net/item/mediplus-medical-and-doctor-html-template/full_screen_preview/26665910?_ga=2.145092285.888558928.1591971968-344530658.1588061879"
        target="_blank"
        class="btn"
        >Pro Version Demo</a
      >
      <a
        href="https://themeforest.net/item/mediplus-medical-and-doctor-html-template/26665910"
        target="_blank"
        class="btn"
        >Buy Pro Version</a
      >
    </div>
  </ul> -->

  <!-- Header Area -->

  <!-- End Header Area -->

  <!-- ======= Header ======= -->
  <MyHeader />
  <!-- End Header -->

  <router-view></router-view>

  <!-- ======= Footer ======= -->
  <MyFooter />
  <!-- End Footer -->
</template>

<script>
import MyFooter from "./components/Footer.vue";
import MyHeader from "./components/Header.vue";

export default {
  name: "App",
  components: {
    MyHeader,
    MyFooter,
  },
};
</script>
