<template>
  <BreadCrumbs title="header.taskPosted" />
  <MyBlogs />
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import MyBlogs from "@/components/TaskList.vue";

export default {
  name: "TaskPostedPage",
  components: { MyBlogs, BreadCrumbs },
};
</script>
