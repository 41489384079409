<template>
  <BreadCrumbs title="header.howItWorks" />
  <HowWorks :display="false" />
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import HowWorks from "@/components/HowWorks.vue";

export default {
  name: "HowItWorksPage",
  components: {
    HowWorks,
    BreadCrumbs,
  },
};
</script>
