<template>
  <footer id="footer" class="footer">
    <!-- Footer Top -->
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12">
            <div class="single-footer">
              <h2>{{ $t("footer.downloadAppTitle") }}</h2>
              <p></p>
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12 mb-4">
                  <img src="/img/qrcode-apple.png" alt="" class="img-fluid" />
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <img src="/img/qrcode-apple.png" alt="" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-6 col-12">
            <div class="single-footer f-link">
              <h2>{{ $t("footer.quickLinksTitle") }}</h2>
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <ul>
                    <li>
                      <router-link :to="{ name: 'Home' }"
                        ><i class="fa fa-caret-right" aria-hidden="true"></i>
                        {{ $t("footer.home") }}</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'About' }">
                        <i class="fa fa-caret-right" aria-hidden="true"></i>
                        {{ $t("footer.aboutUs") }}</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'TermConditions' }"
                        ><i class="fa fa-caret-right" aria-hidden="true"></i>
                        {{ $t("footer.termsAndConditions") }}</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'PrivacyPolicy' }"
                        ><i class="fa fa-caret-right" aria-hidden="true"></i>
                        {{ $t("footer.privacyAndPolicies") }}</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'CommunityEthics' }"
                        ><i class="fa fa-caret-right" aria-hidden="true"></i>
                        {{ $t("footer.communityEthics") }}</router-link
                      >
                    </li>
                  </ul>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <ul>
                    <li>
                      <router-link :to="{ name: 'FindService' }"
                        ><i class="fa fa-caret-right" aria-hidden="true"></i>
                        {{ $t("footer.services") }}</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'HowItWorks' }"
                        ><i class="fa fa-caret-right" aria-hidden="true"></i>
                        {{ $t("footer.howItWorks") }}</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'TaskPosted' }"
                        ><i class="fa fa-caret-right" aria-hidden="true"></i>
                        {{ $t("footer.tasksPosted") }}</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'HelpCenter' }"
                        ><i class="fa fa-caret-right" aria-hidden="true"></i>
                        {{ $t("footer.faq") }}</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'Contact' }"
                        ><i class="fa fa-caret-right" aria-hidden="true"></i>
                        {{ $t("footer.contactUs") }}</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <div class="single-footer">
              <h2>{{ $t("footer.discoverTitle") }}</h2>
              <p>{{ $t("footer.followUsOn") }}</p>
              <!-- Social -->
              <ul class="social">
                <li>
                  <a href="#"><i class="icofont-facebook"></i></a>
                </li>
                <li>
                  <a href="#"><i class="icofont-google-plus"></i></a>
                </li>
                <li>
                  <a href="#"><i class="icofont-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="icofont-vimeo"></i></a>
                </li>
                <li>
                  <a href="#"><i class="icofont-pinterest"></i></a>
                </li>
              </ul>
              <!-- End Social -->
              <br />
              <p>{{ $t("footer.customerServices") }}</p>
              <p>{{ $t("footer.emailAddress") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/ End Footer Top -->

    <!-- Copyright -->
    <div class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="copyright-content">
              <p>
                {{ $t("footer.copyrightText") }}
                <a href="https://wamerci.com" target="_blank">GSIM</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/ End Copyright -->
  </footer>
</template>

<script>
export default {
  name: "MyFooter",
};
</script>
