<template>
  <!-- Slider Area -->
  <HomeSlider />
  <!--/ End Slider Area -->

  <!-- Start Schedule Area -->
  <HomeSchedule />
  <!--/End Start schedule Area -->

  <!-- Start Feautes -->
  <HowWorks :display="true" />
  <!--/ End Feautes -->

  <!-- Start Fun-facts -->
  <HomeFunFact />
  <!--/ End Fun-facts -->

  <!-- End Blog Area -->
  <TaskList />
  <!-- End Blog Area -->

  <!-- Start Newsletter Area -->
  <NewsLetter />
  <!-- /End Newsletter Area -->
</template>

<script>
import HowWorks from "../components/HowWorks.vue";
import NewsLetter from "../components/Newsletter.vue";
import TaskList from "../components/TaskList.vue";
import HomeSlider from "@/components/HomeSlider.vue";
import HomeSchedule from "@/components/HomeSchedule.vue";
import HomeFunFact from "@/components/HomeFunFact.vue";

export default {
  name: "HomePage",
  components: {
    HomeSlider,
    HomeSchedule,
    HomeFunFact,
    HowWorks,
    NewsLetter,
    TaskList,
  },
};
</script>
