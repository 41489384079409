<template>
  <BreadCrumbs title="header.helpCenter" />
  <MyFAQ />
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import MyFAQ from "@/components/FAQ.vue";

export default {
  name: "HelpcenterPage",
  components: { MyFAQ, BreadCrumbs },
};
</script>
