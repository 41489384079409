<template>
  <section class="why-choose section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title">
            <h2>{{ $t("whyChoose.sectionTitle") }}</h2>
            <img src="img/section-img.png" alt="#" />
            <p>{{ $t("whyChoose.sectionSubtitle") }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-12">
          <!-- Start Choose Left -->
          <div class="choose-left">
            <h3>{{ $t("whyChoose.whoWeAreTitle") }}</h3>
            <p>{{ $t("whyChoose.whoWeAreText") }}</p>

            <h3>{{ $t("whyChoose.missionTitle") }}</h3>
            <p>{{ $t("whyChoose.missionText") }}</p>

            <h3>{{ $t("whyChoose.whyTitle") }}</h3>
            <div class="row">
              <div class="col-lg-12">
                <ul class="list">
                  <li>
                    <i class="fa fa-caret-right"></i>
                    {{ $t("whyChoose.reason1") }}
                  </li>
                  <li>
                    <i class="fa fa-caret-right"></i>
                    {{ $t("whyChoose.reason2") }}
                  </li>
                  <li>
                    <i class="fa fa-caret-right"></i>
                    {{ $t("whyChoose.reason3") }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- End Choose Left -->
        </div>
        <div class="col-lg-6 col-12">
          <!-- Start Choose Rights -->
          <img src="img/about.svg" alt="#" />

          <!-- End Choose Rights -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>
