export default {
  header: {
    aboutUs: "About us",
    contact: "Contact",
    helpCenter: "Help Center",
    phoneNumber: "+221 33 961 02 99",
    emailAddress: "{'hello@wamerci.com'}",
    languageLink: "🇫🇷", // English language link
    home: "Home",
    taskPosted: "Task Posted",
    services: "Services",
    howItWorks: "How It Works",
    contactUs: "Contact Us",
    postTask: "Post Task",
    logoAltText: "Logo",
  },
  slider: {
    slide1: {
      title: "Welcome to <span>WaMerci</span>",
      subtitle:
        "Connect with local <span>professionals</span> for your service needs.",
      description:
        "Discover a new way to find reliable and qualified local professionals. Our user-friendly platform allows you to quickly connect with the experts you need.",
      earnMoneyButton: "Earn Money with WaMerci",
      learnMoreButton: "Learn More",
    },
    slide2: {
      title:
        "Get the <span>best service </span> at your own budget or <span>earn</span> the best rate with your <span>skills</span>",
      description:
        "Join WaMerci today and unlock a world of convenience and reliability for all your service needs. Whether it's a home repair, event planning, or tutoring services, we've got you covered.",
      findServicesButton: "Find Services",
      aboutUsButton: "About Us",
    },
  },
  schedule: {
    item1: {
      title: "Effortless Connection",
      description:
        "Connect instantly with a network of trusted professionals ready to meet your needs.",
      learnMore: "LEARN MORE",
    },
    item2: {
      title: "Quality Assurance",
      description:
        "Every service provider on WaMerci is vetted for excellence, so you can rest assured that you're getting top-notch service every time.",
      learnMore: "LEARN MORE",
    },
    item3: {
      title: "Secure Transactions",
      description:
        "Our robust payment system ensures that your transactions are safe and secure, giving you peace of mind throughout the entire process.",
      learnMore: "LEARN MORE",
    },
  },
  features: {
    title: "How WaMerci Works?",
    description: "Streamlined Service Engagement",
    feature1: {
      title: "1. Post Your Service Needs",
      description:
        "Users can create detailed listings of their service requirements, specifying the type of service needed, location, and any specific deadlines or preferences.",
    },
    feature2: {
      title: "2. Receive Offers from Professionals",
      description:
        "Qualified professionals in the WaMerci network can view these listings and submit offers tailored to the user's needs.",
    },
    feature3: {
      title: "3. Compare and Select the Best Offer",
      description:
        "Users can compare offers from different professionals, considering factors such as quality, price, and provider reputation.",
    },
    feature4: {
      title: "4. Secure Payment Processing",
      description:
        "WaMerci facilitates secure and transparent payment transactions between users and service providers.",
    },
    feature5: {
      title: "5. Job Completion and Validation",
      description:
        "Upon selecting an offer, the chosen professional completes the agreed-upon work according to the terms and conditions outlined in the offer.",
    },
    feature6: {
      title: "6. Funds Transfer",
      description:
        "Once the work is validated and approved by the user, WaMerci facilitates the seamless transfer of funds from the client to the service provider.",
    },
  },
  funFacts: {
    users: "Users",
    professionals: "Professionals",
    servicesPosted: "Services Posted",
    servicesDone: "Services Done",
  },
  blogSection: {
    title: "Discover Diverse Service Requests",
    description: `Browse through a diverse range of service requests posted by users just like you. From home repairs and landscaping to graphic design projects and language tutoring, there's something for every skill set and interest.`,
  },
  newsletterSection: {
    title: "Sign up for newsletter",
    description:
      "Feel free to customize it further to fit the tone and specifics of your newsletter!",
    placeholder: "Your email address",
    subscribeButton: "Subscribe",
  },
  footer: {
    downloadAppTitle: "Download App",
    quickLinksTitle: "Quick Links",
    aboutUs: "About us",
    home: "Home",
    termsAndConditions: "Terms and Conditions",
    privacyAndPolicies: "Privacy and Policies",
    communityEthics: "Community Ethics",
    services: "Services",
    howItWorks: "How It Works",
    tasksPosted: "Tasks Posted",
    faq: "FAQ",
    contactUs: "Contact Us",
    discoverTitle: "Discover",
    followUsOn: "Follow us on:",
    customerServices: "Customer Services",
    emailAddress: "{'hello@wamerci.com'}",
    copyrightText: "© WaMerci 2018 All Rights Reserved by | Designed by",
  },
  contactUs: {
    contactWithTitle: "Contact With Us",
    contactSubtitle:
      "If you have any questions please feel free to contact with us.",
    nameLabel: "Name",
    emailLabel: "Email",
    phoneLabel: "Phone",
    subjectLabel: "Subject",
    messageLabel: "Your Message",
    sendButton: "Send",
    subscribeLabel: "Do you want to subscribe our Newsletter?",
    phoneNumber: "+(221) 33 961 02 99",
    emailAddress: "{'contact@wamerci.com'}",
    addressLine1: "Saint-Louis",
    addressLine2: "Route Nationale 2, Sor",
    openingHours: "Mon - Sat: 8am - 5pm",
    closedOnSunday: "Sunday Closed",
    formLoading: "Loading...",
    formError: "Oops! Something went wrong. Please try again later.",
    formSuccess: "Your message has been sent. Thank you!",
  },

  services: {
    sectionTitle: "Our Services",
    sectionSubtitle:
      "Explore the various services we offer to meet your needs.",
    modelInfluencer: {
      title: "Model & Influencer",
      description:
        "Discover talented models and influencers for your brand collaborations or promotional campaigns. Our professionals can enhance your brand's reach and engagement.",
    },
    writerTranslator: {
      title: "Writer & Translator",
      description:
        "Find skilled writers and translators to assist with your projects. Whether you need content creation, document translation, or language services, our professionals are here to help.",
    },
    flyerDelivery: {
      title: "Flyer Delivery",
      description:
        "Need efficient flyer distribution for your business or event? Our professionals ensure your flyers reach the right audience, boosting your visibility and engagement.",
    },
    homeCaretaker: {
      title: "Home Caretaker",
      description:
        "Entrust your home's care to reliable professionals. From housekeeping to pet sitting, our caretakers ensure your home is well-maintained and secure.",
    },
    cleaner: {
      title: "Cleaner",
      description:
        "Hire professional cleaners to keep your space spotless and organized. From residential cleaning to commercial janitorial services, our cleaners ensure a clean and healthy environment.",
    },
    photographerVideographer: {
      title: "Photographer & Videographer",
      description:
        "Capture life's moments with skilled photographers and videographers. Whether you need professional portraits, event coverage, or promotional videos, our experts deliver stunning visuals.",
    },
    temporaryStaff: {
      title: "Temporary Staff",
      description:
        "Fulfill your staffing needs with temporary professionals. From administrative support to event staffing, our temporary staff are skilled and reliable to meet your short-term requirements.",
    },
    renovator: {
      title: "Renovator",
      description:
        "Transform your space with experienced renovators. Whether it's home remodeling or commercial renovation projects, our renovators deliver quality craftsmanship and design solutions.",
    },
    deliveryHelper: {
      title: "Delivery Helper",
      description:
        "Need assistance with deliveries? Hire a reliable delivery helper to ensure your packages reach their destination safely and on time. Our professionals are ready to assist with your delivery needs.",
    },
    nannyBabysitter: {
      title: "Nanny/Babysitter",
      description:
        "Entrust your child's care to experienced nannies and babysitters. Whether you need childcare for a night out or regular support, our caregivers ensure a safe and nurturing environment for your little ones.",
    },
    designSupport: {
      title: "Design Support",
      description:
        "Get professional design support for your projects. Whether you need graphic design, UI/UX design, or branding assistance, our experts provide creative solutions tailored to your needs.",
    },
    plumber: {
      title: "Plumber",
      description:
        "Need plumbing services? Hire skilled plumbers to address your plumbing issues efficiently. From pipe repairs to installations, our plumbers ensure reliable solutions for your plumbing needs.",
    },
    electriciansAircond: {
      title: "Electricians & Aircond Service",
      description:
        "Ensure your electrical and air conditioning systems are in top condition with professional electricians and air conditioning service providers. From installations to repairs, our experts keep your systems running smoothly.",
    },
    applianceService: {
      title: "Appliance Service & Repair",
      description:
        "Ensure your appliances run smoothly with professional appliance service and repair. From washing machines to refrigerators, our experts provide reliable solutions to keep your appliances in top condition.",
    },
    painter: {
      title: "Painter",
      description:
        "Transform your space with expert painting services. Whether you need interior or exterior painting, our painters deliver quality workmanship and attention to detail to bring your vision to life.",
    },
    webSoftwareDeveloper: {
      title: "Web & Software Developer",
      description:
        "Develop your online presence with skilled web and software developers. From website design to custom software solutions, our developers create tailored solutions to meet your digital needs.",
    },
    transporter: {
      title: "Transporter",
      description:
        "Need reliable transportation services? Hire professional transporters for safe and efficient delivery of goods. From local deliveries to long-distance transport, our transporters ensure your items reach their destination securely.",
    },
    carWashService: {
      title: "Car Wash & Service",
      description:
        "Keep your car looking its best with professional car wash and detailing services. From exterior washing to interior cleaning, our experts ensure your vehicle shines inside and out.",
    },
    makeupArtist: {
      title: "Make-up Artist",
      description:
        "Enhance your natural beauty with the help of skilled makeup artists. Whether it's for a special occasion or everyday glam, our makeup artists create stunning looks tailored to your preferences.",
    },
    personalShopper: {
      title: "Personal Shopper",
      description:
        "Save time and enjoy personalized shopping experiences with professional personal shoppers. From fashion to groceries, our personal shoppers cater to your needs and preferences.",
    },
    homeTuition: {
      title: "Home Tuition",
      description:
        "Enhance your learning experience with personalized home tuition services. Our tutors provide tailored lessons to help you achieve your academic goals from the comfort of your home.",
    },
  },
  whyChoose: {
    sectionTitle: "Empowering Your Service Journey with WaMerci",
    sectionImage: "img/section-img.png",
    sectionSubtitle:
      "Welcome to WaMerci - Where Service Excellence Meets Innovation!",
    whoWeAreTitle: "Who we are",
    whoWeAreText:
      "At WaMerci, we're not just revolutionizing the way people access services; we're reshaping the entire service landscape. Our platform is more than just a marketplace; it's a dynamic ecosystem designed to connect individuals with the best professionals in their area seamlessly.",
    missionTitle: "Our Mission: Simplifying Service Solutions",
    missionText:
      "WaMerci was born from a desire to simplify the often complex and time-consuming process of finding reliable service providers. We believe that everyone deserves access to hassle-free, high-quality services, and our mission is to make that a reality.",
    whyTitle: "Why WaMerci?",
    reason1:
      "Community-Centric Approach: We're more than just a platform; we're a community of service enthusiasts dedicated to making your life easier.",
    reason2:
      "Innovation at Its Core: With cutting-edge technology and forward-thinking solutions, we're constantly raising the bar for service excellence.",
    reason3:
      "Your Satisfaction, Our Priority: Your satisfaction is not just important to us; it's our driving force. We go above and beyond to ensure that every interaction with WaMerci exceeds your expectations.",
    rightImage: "img/about.svg",
  },
  faq: {
    header: "We're Here to Assist You",
    description:
      "Find answers to common questions and queries in our comprehensive FAQs section. Whether you're a service seeker or a provider, we've got you covered with helpful information to address your concerns.",
    feature1: {
      title: "1. What is WaMerci?",
      description:
        "WaMerci is a project aimed at creating a community electronic portal that facilitates connections between individuals and local professionals to meet their service needs.",
    },
    feature2: {
      title: "2. How can I post my service needs on WaMerci?",
      description:
        "You can post your service needs on WaMerci using the user-friendly platform provided. Simply describe your needs in terms of the required services, specifying relevant details such as the nature of the service, location, and deadlines.",
    },
    feature3: {
      title: "3. How can professionals submit offers in response to my needs?",
      description:
        "Qualified and interested professionals can submit offers in response to your posted needs by providing details about their services, skills, rates, and availability via the WaMerci platform.",
    },
    feature4: {
      title: "4. How can I select the best offer?",
      description:
        "You can compare the different offers received from professionals and select the one that best meets your needs, taking into account various factors such as quality, price, and the provider's reputation.",
    },
    feature5: {
      title: "5. How are secure payments ensured on WaMerci?",
      description:
        "WaMerci ensures a secure and transparent payment process for users by facilitating financial transactions through its platform, thus guaranteeing trust and security in transactions.",
    },
    feature6: {
      title:
        "6. What happens once I have selected a professional to perform the work?",
      description:
        "Once you have selected a professional to perform the work, they will complete it according to the terms and conditions defined in the offer accepted by you, providing quality services within the agreed timeframe.",
    },
    feature7: {
      title: "7. What if I have issues or disputes with the completed work?",
      description:
        "You can validate the quality and compliance of the completed work with the support of the WaMerci team. In case of issues or disputes, our team is here to help you resolve the situation.",
    },
    feature8: {
      title:
        "8. How are funds transferred between parties once the work is completed?",
      description:
        "WaMerci facilitates the transfer of funds from the client to the service provider once the work has been validated and approved by users, in accordance with the agreements between the parties, ensuring transparency and reliability in financial transactions.",
    },
    feature9: {
      title:
        "9. Does WaMerci guarantee the quality of services provided by professionals?",
      description:
        "WaMerci strives to maintain high-quality standards by selecting qualified professionals and overseeing the work validation process. However, the final quality of services also depends on communication between users and service providers.",
    },
    feature10: {
      title:
        "10. How can I be sure of the reliability of professionals on WaMerci?",
      description:
        "WaMerci implements verification mechanisms to ensure the reliability of professionals registered on its platform. This may include background checks, evaluations from previous clients, and strict selection criteria.",
    },
    feature11: {
      title: "11. Are there any fees associated with using WaMerci?",
      description:
        "WaMerci may charge service fees to facilitate transactions and maintain the platform. These fees may vary depending on the type of service and agreements made between the parties.",
    },
    feature12: {
      title: "12. How can I contact WaMerci's customer support if needed?",
      description:
        "To contact WaMerci's customer support, you can generally find a contact link or form on the platform's website. You can also look for contact information in the help or FAQ section.",
    },
    feature13: {
      title: "13. How can I manage my account settings on WaMerci?",
      description:
        "To manage your account settings on WaMerci, log in to your account and navigate to the settings section where you can update your profile information, notification preferences, and other account details.",
    },
    feature14: {
      title: "14. Is WaMerci available in all regions?",
      description:
        "WaMerci may be available in specific regions, depending on the availability of professionals and user demand. Please check the availability of the platform in your specific region.",
    },
    feature15: {
      title: "15. What types of services can I find on WaMerci?",
      description:
        "WaMerci aims to offer a variety of services to meet users' diverse needs. This may include services such as home repairs, IT support, gardening, cleaning, and more. The range of available services may vary depending on the region and local demand.",
    },
    feature16: {
      title: "16. Does WaMerci protect my personal information?",
      description:
        "WaMerci takes privacy protection seriously and implements security measures to safeguard your personal information. Your data is generally used solely to facilitate transactions and provide effective service, in accordance with WaMerci's privacy policy.",
    },
  },

  CommunityEthics: {
    title: "Community Code of Conduct",
    sections: {
      introduction: {
        title: "1. Introduction",
        content:
          "At WaMerci, we are committed to maintaining a respectful, professional, and inclusive environment for all users and professionals on our platform. This Code of Conduct outlines the expectations for behavior and interaction within our community.",
      },
      respectAndCourtesy: {
        title: "2. Respect and Courtesy",
        content:
          "We expect all community members to treat each other with respect and courtesy. Respecting others' opinions, beliefs, and cultures is fundamental to a healthy environment.",
        points: [
          "Appropriate Language: Use respectful language and avoid insults, personal attacks, and discriminatory remarks.",
          "Respect for Differences: Be open to differences in culture, opinion, and belief.",
          "Online Behavior: Maintain professional and respectful conduct in all online communications.",
        ],
      },
      professionalIntegrity: {
        title: "3. Professional Integrity",
        content:
          "Professionals must act with integrity and transparency in all interactions with users. Honesty and ethics are crucial to maintaining trust on our platform.",
        points: [
          "Transparency: Provide clear and honest information about your services, skills, and fees.",
          "Commitment: Fulfill commitments made to clients and provide high-quality services.",
          "Confidentiality: Protect clients' personal information and respect their privacy.",
        ],
      },
      conflictResolution: {
        title: "4. Conflict Resolution",
        content:
          "In case of a conflict or disagreement, we encourage constructive and respectful resolution. Parties involved should act in good faith to reach a satisfactory solution.",
        points: [
          "Open Communication: Engage in open and honest discussion to resolve issues.",
          "Mediation: If needed, seek assistance from our support team to facilitate conflict resolution.",
          "Respect for Decisions: Accept mediation decisions and act accordingly.",
        ],
      },
      prohibitedBehavior: {
        title: "5. Prohibited Behavior",
        content:
          "Certain actions are strictly prohibited on our platform. The following behaviors will not be tolerated:",
        points: [
          "Harassment: Any form of harassment or threatening behavior towards other community members.",
          "Discrimination: Discriminatory behavior based on race, gender, sexual orientation, religion, or any other protected characteristic.",
          "Fraud: Any form of deceit or fraud in interactions with users.",
          "Abuse: Misuse or unauthorized use of the platform, including spam and unsolicited advertisements.",
        ],
      },
      memberResponsibilities: {
        title: "6. Member Responsibilities",
        content:
          "Each community member is responsible for their actions and must ensure they comply with this Code of Conduct. Appropriate measures will be taken in case of violations to maintain the integrity of the platform.",
        points: [
          "Compliance: Adhere to the rules and guidelines established in this Code of Conduct.",
          "Reporting: Report any suspicious or inappropriate behavior or activity to our support team.",
          "Responsibility: Take responsibility for your actions and their impact on the community.",
        ],
      },
      contactAndReporting: {
        title: "7. Contact and Reporting",
        content:
          "For any questions regarding this Code of Conduct or to report violations, please contact our support team at ",
      },
    },
  },
  PrivacyPolicy: {
    title: "Privacy Policy",
    sections: {
      introduction: {
        title: "1. Introduction",
        content:
          "At WaMerci, we are committed to protecting your privacy. This privacy policy explains how we collect, use, and safeguard your personal information when you use our website and services.",
      },
      informationCollection: {
        title: "2. Information Collection",
        content:
          "We collect various types of information when you use our platform, including:",
        points: [
          "Personal information: Name, email address, phone number, and other contact details.",
          "Account information: Details about your user account, including preferences and interactions with our service.",
          "Payment data: Information needed to process financial transactions, such as credit card details.",
          "Usage data: Data related to your use of the site, including your IP address, browser, and actions on the platform.",
        ],
      },
      informationUse: {
        title: "3. Use of Information",
        content: "We use your personal information to:",
        points: [
          "Provide and improve our services: To meet your needs and enhance our platform.",
          "Process payments: To handle financial transactions securely.",
          "Communication: To send you important information, updates, and promotional offers (with your consent).",
          "Analysis: To analyze site usage and improve our services.",
        ],
      },
      informationProtection: {
        title: "4. Information Protection",
        content:
          "We implement technical and organizational security measures to protect your personal information from loss, unauthorized access, disclosure, alteration, or destruction.",
        points: [
          "Encryption: Use of encryption protocols to secure data during transmission.",
          "Access controls: Restricting access to personal information to authorized employees and service providers only.",
          "Security audits: Regular audits to identify and address vulnerabilities.",
        ],
      },
      informationSharing: {
        title: "5. Information Sharing",
        content:
          "We share your personal information with third parties only in the following situations:",
        points: [
          "With your consent: When you have agreed to share your information.",
          "With service providers: For third-party services that assist us in providing or improving our services.",
          "For legal obligations: If we are required to disclose information to comply with the law or regulations.",
          "In case of business transfer: In the event of a merger, acquisition, or sale of our business.",
        ],
      },
      yourRights: {
        title: "6. Your Rights",
        content:
          "As a user, you have several rights regarding your personal information:",
        points: [
          "Right of access: You can request access to your personal information we hold.",
          "Right to rectification: You can request correction of inaccurate or incomplete information.",
          "Right to erasure: You can request deletion of your personal information under certain conditions.",
          "Right to restriction of processing: You can request limitation on the use of your personal information.",
          "Right to object: You can object to the use of your personal information for marketing purposes.",
        ],
        conclusion:
          "To exercise your rights, please contact us using the information provided in the following section.",
      },
      cookies: {
        title: "7. Cookies and Similar Technologies",
        content:
          "We use cookies and similar technologies to enhance your experience on our site. You can configure your browser to refuse cookies, but this may affect certain site features.",
      },
      policyUpdates: {
        title: "8. Privacy Policy Updates",
        content:
          "We may update this privacy policy from time to time. Any changes will be posted on this page with an updated revision date. We encourage you to review this policy regularly to stay informed.",
      },
      contact: {
        title: "9. Contact",
        content:
          "If you have any questions regarding this privacy policy or wish to exercise your rights, please contact us at ",
      },
    },
  },
  TermsConditions: {
    title: "Terms and Conditions",
    sections: {
      introduction: {
        title: "1. Introduction",
        content:
          "Welcome to WaMerci. By using our website and services, you agree to comply with the following terms and conditions. Please read these terms carefully before using our platform.",
      },
      servicePosting: {
        title: "2. Service Posting",
        content:
          "Users have the option to post their service needs on our platform. When posting, it is essential to provide detailed information, including the nature of the service, location, deadlines, and any specific requirements.",
        points: [
          "Nature of the service: Describe the required service precisely.",
          "Location: Indicate where the service is needed.",
          "Deadlines: Mention the desired deadlines for the service.",
          "Specific requirements: Add any specific requirements or conditions.",
        ],
      },
      offerSubmission: {
        title: "3. Offer Submission",
        content:
          "Professionals can respond to service requests by submitting offers via the platform. Offers must include the following information:",
        points: [
          "Service description: Details about the offered services.",
          "Skills: Relevant qualifications and skills of the provider.",
          "Rates: Cost of the proposed services, including any applicable taxes.",
          "Availability: Dates and times when the professional is available.",
        ],
      },
      offerSelection: {
        title: "4. Offer Selection",
        content:
          "Users are responsible for selecting the offer that best meets their needs. Selection criteria may include:",
        points: [
          "Quality: Check the professional's skills and experience.",
          "Price: Compare the proposed rates.",
          "Reputation: Review ratings and feedback from other users.",
          "Deadlines: Ensure that the completion deadlines match your needs.",
        ],
      },
      securePayment: {
        title: "5. Secure Payment",
        content:
          "Payment for services must be made through our platform, ensuring the security and transparency of transactions. We use encryption technologies to protect users' financial information.",
        points: [
          "Payment methods: Credit card, PayPal, and other secure methods.",
          "Payment confirmation: Receive an email confirmation after each transaction.",
          "Refund policy: See our refund policy for more details.",
        ],
      },
      workCompletion: {
        title: "6. Work Completion",
        content:
          "Professionals must complete services in accordance with the terms agreed upon in the accepted offer. Adherence to deadlines and quality is mandatory. Any modifications or additional requests must be agreed upon between the user and the professional.",
        points: [
          "Adherence to terms: Services must be performed as specified.",
          "Communication: Inform the user of any changes or delays.",
          "Quality: Ensure customer satisfaction by providing high-quality services.",
        ],
      },
      workValidation: {
        title: "7. Work Validation",
        content:
          "After services are completed, users must validate the work done. WaMerci oversees and intervenes in case of disputes or issues. Users can report problems or request corrections.",
        points: [
          "Validation process: Review and approve the work via the platform.",
          "Support: Contact our team for any assistance or disputes.",
          "Corrections: Professionals must make requested corrections within a reasonable time.",
        ],
      },
      fundTransfer: {
        title: "8. Fund Transfer",
        content:
          "Funds will be transferred to the service provider only after the work is validated by the user. WaMerci ensures transparency in managing payments and fund transfers.",
        points: [
          "Transfer deadlines: Payments will be made within the stipulated time after validation.",
          "Transparency: Track payment status via the platform.",
          "Confirmation: Receive a transfer confirmation once the funds are sent.",
        ],
      },
      termsModification: {
        title: "9. Modification of Terms",
        content:
          "WaMerci reserves the right to modify these terms and conditions at any time. Changes will be posted on this page and will take effect immediately upon publication. It is your responsibility to regularly review these terms.",
      },
      responsibilityLimitation: {
        title: "10. Responsibilities and Limitation of Liability",
        content:
          "WaMerci cannot be held responsible for issues arising between users and professionals. We act as an intermediary and do not guarantee the quality of the services provided. Users should exercise their judgment when selecting professionals.",
      },
      contact: {
        title: "11. Contact",
        content:
          "For any questions regarding these terms and conditions or for any other inquiries, please contact us at ",
      },
    },
  },
  download: {
    features: [
      {
        title: "Get the Best Services at your own budget",
        description:
          "Users can create detailed listings of their service requirements, specifying the type of service needed, location, and any specific deadlines or preferences.",
      },
      {
        title: "Earn the best rate with your Skills",
        description:
          "Qualified professionals in the WaMerci network can view these listings and submit offers tailored to the user's needs.",
      },
    ],
    howTo: {
      title: "How to do it?",
      description:
        "Just Click or Scan the qr-code et download the app WaMerci from PlayStore or AppleStore",
    },
    links: [
      {
        store: "PlayStore",
        image: "/img/qrcode-apple.png",
      },
      {
        store: "AppleStore",
        image: "/img/qrcode-apple.png",
      },
    ],
  },
};
