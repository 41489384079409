<template>
  <header class="header">
    <!-- Topbar -->
    <div class="topbar">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-5 col-12">
            <!-- Contact -->
            <ul class="top-link">
              <li>
                <router-link :to="{ name: 'About' }">{{
                  $t("header.aboutUs")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Contact' }">{{
                  $t("header.contact")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'HelpCenter' }">{{
                  $t("header.helpCenter")
                }}</router-link>
              </li>
            </ul>
            <!-- End Contact -->
          </div>
          <div class="col-lg-6 col-md-7 col-12">
            <!-- Top Contact -->
            <ul class="top-contact">
              <li><i class="fa fa-phone"></i>{{ $t("header.phoneNumber") }}</li>
              <li>
                <i class="fa fa-envelope"></i
                ><a href="mailto:hello@wamerci.com">{{
                  $t("header.emailAddress")
                }}</a>
              </li>
              <li>
                <a
                  href="#"
                  v-if="$i18n.locale === 'fr'"
                  :to="{ name: 'Home' }"
                  @click="changeLanguage('en')"
                  >{{ $t("header.languageLink") }}</a
                >
                <a
                  href="#"
                  v-if="$i18n.locale === 'en'"
                  :to="{ name: 'Home' }"
                  @click="changeLanguage('fr')"
                  >{{ $t("header.languageLink") }}</a
                >
              </li>
            </ul>
            <!-- End Top Contact -->
          </div>
        </div>
      </div>
    </div>
    <!-- End Topbar -->
    <!-- Header Inner -->
    <div class="header-inner">
      <div class="container">
        <div class="inner">
          <div class="row">
            <div class="col-lg-2 col-md-2 col-12">
              <!-- Start Logo -->
              <div class="logo">
                <router-link :to="{ name: 'Home' }"
                  ><img src="img/logo.png" :alt="$t('header.logoAltText')"
                /></router-link>
              </div>
              <!-- End Logo -->
              <!-- Mobile Nav -->
              <div class="mobile-nav"></div>
              <!-- End Mobile Nav -->
            </div>
            <div class="col-lg-8 col-md-9 col-12">
              <!-- Main Menu -->
              <div class="main-menu">
                <nav class="navigation">
                  <ul class="nav menu">
                    <li :class="{ active: $route.name === 'Home' }">
                      <router-link :to="{ name: 'Home' }">{{
                        $t("header.home")
                      }}</router-link>
                    </li>
                    <li :class="{ active: $route.name === 'TaskPosted' }">
                      <router-link :to="{ name: 'TaskPosted' }">{{
                        $t("header.taskPosted")
                      }}</router-link>
                    </li>
                    <li :class="{ active: $route.name === 'FindService' }">
                      <router-link :to="{ name: 'FindService' }">{{
                        $t("header.services")
                      }}</router-link>
                    </li>
                    <li :class="{ active: $route.name === 'HowItWorks' }">
                      <router-link :to="{ name: 'HowItWorks' }">{{
                        $t("header.howItWorks")
                      }}</router-link>
                    </li>
                    <li :class="{ active: $route.name === 'Contact' }">
                      <router-link :to="{ name: 'Contact' }">{{
                        $t("header.contactUs")
                      }}</router-link>
                    </li>
                  </ul>
                </nav>
              </div>
              <!--/ End Main Menu -->
            </div>
            <div class="col-lg-2 col-12">
              <div class="get-quote">
                <router-link :to="{ name: 'Download' }" class="btn">{{
                  $t("header.postTask")
                }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/ End Header Inner -->
  </header>
</template>

<script>
export default {
  name: "MyHeader",

  mounted() {
    // Retrieve language preference from localStorage or other storage
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      this.$i18n.locale = savedLanguage;
    }
  },
  methods: {
    changeLanguage(language) {
      this.$i18n.locale = language;
      // Store language preference in localStorage or other storage
      localStorage.setItem("language", language);
    },
  },
};
</script>
