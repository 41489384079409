<template>
  <BreadCrumbs title="Not Found" />
  <div class="container text-center my-5">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card p-4 shadow">
          <div class="card-body">
            <h1 class="display-1">404</h1>
            <h2 class="display-4">Oops!</h2>
            <p class="lead">The page you are looking for does not exist.</p>
            <router-link to="/" class="btn btn-primary">Go to Home</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs.vue";

export default {
  name: "NotFoundPage",
  components: {
    BreadCrumbs,
  },
};
</script>
