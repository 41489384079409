<template>
  <div id="fun-facts" class="fun-facts section overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-12">
          <!-- Start Single Fun -->
          <div class="single-fun">
            <i class="icofont icofont-home"></i>
            <div class="content">
              <span class="counter">3468</span>
              <p>{{ $t("funFacts.users") }}</p>
            </div>
          </div>
          <!-- End Single Fun -->
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <!-- Start Single Fun -->
          <div class="single-fun">
            <i class="icofont icofont-user-alt-3"></i>
            <div class="content">
              <span class="counter">557</span>
              <p>{{ $t("funFacts.professionals") }}</p>
            </div>
          </div>
          <!-- End Single Fun -->
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <!-- Start Single Fun -->
          <div class="single-fun">
            <i class="icofont-simple-smile"></i>
            <div class="content">
              <span class="counter">4379</span>
              <p>{{ $t("funFacts.servicesPosted") }}</p>
            </div>
          </div>
          <!-- End Single Fun -->
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <!-- Start Single Fun -->
          <div class="single-fun">
            <i class="icofont icofont-table"></i>
            <div class="content">
              <span class="counter">2332</span>
              <p>{{ $t("funFacts.servicesDone") }}</p>
            </div>
          </div>
          <!-- End Single Fun -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeFunFact",
};
</script>
