<template>
  <BreadCrumbs title="header.aboutUs" />
  <AboutUs />
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import AboutUs from "@/components/About.vue";

export default {
  name: "AboutPage",
  components: {
    AboutUs,
    BreadCrumbs,
  },
};
</script>
